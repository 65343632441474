import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className='header'>
      <div className='container'>
        <Link to={'/'} className='logo-brand'>
          <span className='logo'>
            <img src='./assets/images/icons/logo.png' alt='Centar za Licni Razvoj'/>
          </span>
        </Link>
      </div>
    </header>
  )
}

export default Header;
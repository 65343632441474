import React from 'react';

// const [ loading, setLoading ] = useState(true);
// {loading && <Loading />}
// {!loading && ( 
// )}

// useEffect(() => {
//   setTimeout(() => {
//     (async () => {
//       setLoading(false);
// 		})();
//   }, 700);
// }, []);

const Loading = () => {
  return (
    <div className='loading'>
      <div className='loader'>
        <span className='loader-animation'>
          <span className='grid' />
        </span>
        <span className='loader-text'>
          <span className='grid'>Loading...</span>
        </span>
      </div>
    </div>
  )
}

export default Loading;
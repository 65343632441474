import React from "react";
import { Helmet } from "react-helmet";

const Access = () => {
  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | Holisticki Pristup</title>
        <meta
          name="description"
          content="U našem Centru nudimo različite vrste tehnika, dukacija i individualnih konsultacija zasnovanih na holističkom pristupu. Ovakav način rada uzima u obzir sve spoljašnje i unutrašnje uticaje koju su doveli do izvesnog stanja da bi na najbolji mogući način uz odgovarajuće tehnike došli do optimalog rešenja."
        />
        <meta
          name="keywords"
          content="Psihologija, Lični razvoj, Anksioznost, Alternativno, Holističko, Holistički, Centar za lični razvoj, Depresija, Bolovi, Grupni rad, Podrška, Terapija, Edukacija, Refleksologija, Refleksoterapija, Masaža stopala, Zdravlje, Kurs, Obuka, Kurs refleksologije, Kurs masaže stopala, Bahove kapi, Balkanske esencije, Cvetne esencije"
        />

        <meta name="author" content="Sandra Kesejić" />
      </Helmet>
      <section className="section section-access bg-blue-100">
        <div className="container max-w-screen-lg pb-16">
          <h1 className="title md:inline text-center md:text-left">
            Holistički pristup
          </h1>
        </div>
        <div className="container max-w-screen-lg">
          <p className="paragraph font-semibold text-zinc-600 text-center">
            Šta znači holistički pristup?{" "}
          </p>

          <p className="paragraph">
            Da bi postigli optimalne rezultate, u radu sa klijentom posmatramo
            ga holistično, kao celinu, a ne izolovano njegovu konkretnu
            problematiku. To znači da se sagledava čitava struktura uma, duha i
            tela, kao i unutrašnji i spoljašnji faktori, uticaji i okolnosti.{" "}
          </p>
          <p className="paragraph">
            Disbalans u telu, mentalnom ili emotivnom stanju često nije rezultat
            samo jednog uticaja. Upravo zbog toga moramo da uzmemo sve u obzir i
            celovito i iz različitih perspektiva sagledamo situaciju i time
            nađemo najbolji način da se dođe do željenog optimuma. To je
            holistički pristup, koji omogućava da se detektuju svi faktori koji
            su prouzrokovali neko stanje, a kojih delimično možda nismo bili ni
            svesni. Ovaj pristup ne obuhvata samo jednu oblast terapije, već
            kombinuje više različitih disciplina da bi se postigao najbolji
            mogući rezultat.
          </p>
          <p className="paragraph">
            U radu sa klijentima nam je najbitniji prvi razgovor u kome možemo
            da sagledamo čitavu situaciju i zato je potrebno da ponesete malo
            strpljenja, jer holistički pristup ne nudi instant rešenje, već radi
            na dugoročnom rešavanju problema.
          </p>

          <ul className="list bg-white pl-4 pr-4 rounded-sm">
            <p className="paragraph font-semibold mb-8 text-zinc-600 text-center">
              Neke od tehnika koje koristimo u našem radu su:{" "}
            </p>
            <div className="grid lg:grid-cols-2 gap-4">
              <ul className="border-b-[1px] lg:border-r-[1px] lg:border-b-0">
                <li className="list-item">- Akupresura</li>
                <li className="list-item">- Aromaterapija</li>
                <li className="list-item">- Duboka meditacija</li>
                <li className="list-item">- Energetske vežbe</li>
                <li className="list-item">
                  - Floriterapija (Bachove, Balkanske, Kalifornijske esencije)
                </li>
                <li className="list-item">- Kristaloterapija</li>
                <li className="list-item">- NLP tehnike</li>
              </ul>
              <ul>
                <li className="list-item">- Refleksoterapija</li>
                <li className="list-item">- Reiki</li>
                <li className="list-item">- Shiatsu</li>
                <li className="list-item">- Terapija kroz vođeni razgovor</li>
                <li className="list-item">- Theta healing</li>
                <li className="list-item">- Trigger point</li>
                <li className="list-item">- Yumeiho terapija</li>
              </ul>
            </div>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Access;

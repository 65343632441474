import React from "react";
import { Link } from "react-router-dom";
import { useGlobalHook } from "../../context/AppProvider";

const Footer = () => {
  const { scrollToTop } = useGlobalHook();
  return (
    <footer className="footer">
      <div className="container relative grid min-h-full grid-rows-footer">
        <button
          className="absolute -top-[5.5rem] right-4 w-12 h-12 grid place-items-center bg-white border-2 border-white rounded-sm shadow-md outline-none"
          title="Back to Top"
          onClick={() => scrollToTop()}
        >
          <span
            className="icon flex align-center w-8 h-8 bg-no-repeat bg-[length:32px_32px] opacity-75"
            style={{
              backgroundImage: "url(../assets/images/icons/icon-arrow.png)",
            }}
          />
        </button>

        <div className="footer-top">
          <h2 className="text-center mb-4 pb-4 text-xl md:text-2xl lg:text-3xl font-light text-gray-400 flex justify-center items-center gap-2">
            <span
              className="flex align-end w-8 h-8 bg-no-repeat bg-[length:24px_24px] bg-icon-center opacity-50"
              style={{
                backgroundImage: "url(../assets/images/icons/icon-send.png)",
              }}
            />
            <span>Kontaktirajte nas:</span>
          </h2>
          <div className="contact">
            <div className="call grid bg-green-100 rounded-md w-full min-w-[284px] md:min-w-[320px] md:mb-4 border-[2px] border-white shadow-md gap-3">
              <h4 className="uppercase font-normal text-gray-400 flex justify-center pt-2 items-center gap-2 ">
                <span
                  className="flex align-end w-8 h-8 bg-no-repeat bg-[length:22px_22px] bg-icon-center opacity-75"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-telephone.png)",
                  }}
                />
                <span>Poziv:</span>
              </h4>
              <div className="border-t-[2px] border-white h-full min-h-[5rem] grid ease-in-out duration-300 delay-100 rounded-sm">
                <a
                  href="tel:+38162251227"
                  rel="noreferrer"
                  target="_self"
                  className="font-bold text-zinc-600 hover:text-orange-600 ease-in-out duration-300 delay-100 font-montserrat cursor-pointer grid w-full h-full place-items-center"
                  title="Poziv"
                >
                  <span>+381 62/ 251-227</span>
                </a>
              </div>
            </div>
            <div className="separator border-r-[1px] max-md:sr-only" />
            <div className="message grid bg-yellow-100 rounded-md w-full min-w-[284px] md:min-w-[320px] mb-4 border-[2px] border-white shadow-md gap-3">
              <h4 className="uppercase font-normal text-gray-400 flex justify-center pt-2 items-center gap-2">
                <span
                  className="flex align-end w-8 h-8 bg-no-repeat bg-[length:22px_22px] bg-icon-center opacity-75"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-email.png)",
                  }}
                />
                <span>E-mail:</span>
              </h4>
              <div className="border-t-[2px] border-white h-full min-h-[5rem] grid ease-in-out duration-300 delay-100 rounded-sm">
                <a
                  href="mailto:office@centarzalicnirazvoj.com"
                  rel="noreferrer"
                  target="_self"
                  className="font-bold text-zinc-600 hover:text-orange-600 ease-in-out text-sm md:text-base duration-300 delay-100 font-montserrat cursor-pointer grid w-full h-full place-items-center"
                  title="Poruka"
                >
                  <span>office@centarzalicnirazvoj.com</span>
                </a>
              </div>
            </div>
          </div>
          <div className="contact">
            <div className="call grid bg-purple-100 rounded-md w-full min-w-[284px] md:min-w-[320px] border-[2px] border-white shadow-md gap-3">
              <h4 className="uppercase font-normal text-gray-400 flex justify-center pt-2 items-center gap-2">
                <span
                  className="flex align-end w-8 h-8 bg-no-repeat bg-[length:22px_22px] bg-icon-center opacity-75"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-email.png)",
                  }}
                />
                <span>Viber:</span>
              </h4>
              <div className="border-t-[2px] border-white h-full min-h-[5rem] grid ease-in-out duration-300 delay-100 rounded-sm">
                <a
                  href="https://msng.link/o/?38162251227=vi"
                  rel="noreferrer"
                  target="_self"
                  className="font-bold text-zinc-600 hover:text-orange-600 ease-in-out duration-300 delay-100 font-montserrat cursor-pointer grid w-full h-full place-items-center"
                  title="Viber"
                >
                  <span>+381 62/ 251-227</span>
                </a>
              </div>
            </div>
            <div className="separator border-r-[1px] max-md:sr-only" />
            <div className="message grid bg-blue-100 rounded-md w-full min-w-[284px] md:min-w-[320px] border-[2px] border-white shadow-md gap-3">
              <h4 className="uppercase font-normal text-gray-400 flex justify-center pt-2 items-center gap-2">
                <span
                  className="flex align-end w-8 h-8 bg-no-repeat bg-[length:22px_22px] bg-icon-center opacity-75"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-email.png)",
                  }}
                />
                <span>Skype:</span>
              </h4>
              <div className="border-t-[2px] border-white h-full min-h-[5rem] grid ease-in-out duration-300 delay-100 rounded-sm">
                <a
                  href="https://msng.link/o/?sandra.kesejic=sk"
                  rel="noreferrer"
                  target="_self"
                  className="font-bold text-zinc-600 hover:text-orange-600 ease-in-out text-sm md:text-base duration-300 delay-100 font-montserrat cursor-pointer grid w-full h-full place-items-center"
                  title="Skype"
                >
                  <span>skype.kesejić</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-middle border-t-[1px] mt-4">
          <h4 className="text-center text-gray-400 mb-4">Zapratite nas:</h4>
          <ul className="socials">
            <li>
              <a
                href="https://www.facebook.com/refleksoterapija.rs?mibextid=LQQJ4d"
                title="Facebook"
                rel="noreferrer"
                target="_blank"
                className="socials-link"
              >
                <span
                  className="icon"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-facebook.png)",
                  }}
                ></span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/centarzalicnirazvojkesejic/"
                title="Instagram"
                rel="noreferrer"
                target="_blank"
                className="socials-link"
              >
                <span
                  className="icon"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-instagram.png)",
                  }}
                ></span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCIa_MyX5txMLq02oDF9Rybw"
                title="Youtube"
                rel="noreferrer"
                target="_blank"
                className="socials-link"
              >
                <span
                  className="icon"
                  style={{
                    backgroundImage:
                      "url(../assets/images/icons/icon-youtube.png)",
                  }}
                ></span>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-bottom">
          <p className="copyright text-center font-normal">
            <span>
              Copyright &copy; {new Date().getFullYear()}, All Right Reserved by{" "}
              <Link to={"/"} className="link link-hover">
                Centarzalicnirazvoj
              </Link>
              .
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

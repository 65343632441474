import React from "react";
import { Helmet } from "react-helmet";

const mapURL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2766.173012251425!2d19.673604815782834!3d46.107459398310716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474366b4395d2e53%3A0x607e7250dab77afe!2z0JrQuNGA0LXRiNC60LAgMjksINCh0YPQsdC-0YLQuNGG0LAgMjQwMDA!5e0!3m2!1ssr!2srs!4v1652973245264!5m2!1ssr!2srs";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | Kontakt</title>

        <meta
          name="description"
          content="Centar za lični razvoj i savetovanje - Kesejić ima za cilj da edukuje i podrži svakog pojedinca u unaprešivanju i poboljšanju kvaliteta života, kako na fizičkom, tako i na mentalnom i emotivnom planu."
        />

        <meta
          name="keywords"
          content="Psihologija, Lični razvoj, Anksioznost, Alternativno, Holističko, Holistički, Centar za lični razvoj, Depresija, Bolovi, Grupni rad, Podrška, Terapija, Edukacija, Refleksologija, Refleksoterapija, Masaža stopala, Zdravlje, Kurs, Obuka, Kurs refleksologije, Kurs masaže stopala, Bahove kapi, Balkanske esencije, Cvetne esencije"
        />

        <meta name="author" content="Sandra Kesejić" />
      </Helmet>
      <section className="section section-home bg-yellow-100">
        <div className="container max-w-screen-lg pb-16 text-center">
          <h2 className="title md:inline text-center md:text-left">Kontakt</h2>
        </div>

        <div className="container max-w-screen-lg text-center mb-8">
          <h1 className="mb-8 text-center uppercase font-medium font-xl text-zinc-600 bg-white/[0.5] tracking-wider inline-flex pt-2 pb-2 pl-4 pr-4 rounded-[50px] gap-3 justify-center items-center">
            <span
              className="icon grid w-6 h-6 opacity-50 bg-cover"
              style={{
                backgroundImage: "url(../assets/images/icons/icon-send.png)",
              }}
            ></span>
            <span>Kontaktirajte nas</span>
          </h1>

          <div className="grid md:grid-cols-2 gap-x-4 gap-y-6">
            <div className="bg-white/[0.95] border-[1px] border-white rounded-md shadow-md">
              <a
                href="tel:+38162251227"
                rel="noreferrer"
                target="_blank"
                className="grid gap-4 w-full h-full p-4 text-center min-h-[100px]"
              >
                <span className="text-zinc-500 font-medium">
                  Direktan poziv:
                </span>
                <span className="font-medium ease-in-out duration-200 text-orange-600 hover:text-orange-400">
                  +381 62/ 251-227
                </span>
              </a>
            </div>
            <div className="bg-white/[0.95] border-[1px] border-white rounded-md shadow-md">
              <a
                href="mailto:office@centarzalicnirazvoj.com"
                rel="noreferrer"
                target="_blank"
                className="grid gap-4 w-full h-full p-4 text-center min-h-[100px]"
              >
                <span className="text-zinc-500 font-medium">
                  E-mail poruka:
                </span>
                <span className="font-medium ease-in-out duration-200 text-orange-600 hover:text-orange-400">
                  office@centarzalicnirazvoj.com
                </span>
              </a>
            </div>
            <div className="bg-white/[0.95] border-[1px] border-white rounded-md shadow-md">
              <a
                href="https://msng.link/o/?38162251227=vi"
                rel="noreferrer"
                target="_blank"
                className="grid gap-4 w-full h-full p-4 text-center min-h-[100px]"
              >
                <span className="text-zinc-500 font-medium">Viber poruka:</span>
                <span className="font-medium ease-in-out duration-200 text-orange-600 hover:text-orange-400">
                  +381 62/ 251-227
                </span>
              </a>
            </div>
            <div className="bg-white/[0.95] border-[1px] border-white rounded-md shadow-md">
              <a
                href="https://msng.link/o/?sandra.kesejic=sk"
                rel="noreferrer"
                target="_blank"
                className="grid gap-4 w-full h-full p-4 text-center min-h-[100px]"
              >
                <span className="text-zinc-500 font-medium">
                  Skype video/poruka:
                </span>
                <span className="font-medium ease-in-out duration-200 text-orange-600 hover:text-orange-400">
                  skype.kesejić
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="container max-w-screen-lg pb-16 text-center">
          <h2 className="title inline">Lokacija</h2>
        </div>

        <div className="container max-w-screen-lg text-center">
          <h3 className="mb-8 text-center uppercase font-medium font-xl text-zinc-600 bg-white/[0.5] tracking-wider inline-flex pt-2 pb-2 pl-4 pr-4 rounded-[50px] gap-3 justify-center items-center">
            <span
              className="icon grid w-6 h-6 opacity-50"
              style={{
                backgroundImage:
                  "url(../assets/images/icons/icon-location.png)",
              }}
            ></span>
            <span>Gde se nalazimo</span>
          </h3>
          <iframe
            src={mapURL}
            title="LOKACIJA"
            className="w-full h-[480px] border-[3px] border-white rounded-md shadow-md"
            allowFullScreen={true}
            loading={"lazy"}
            referrerPolicy={"no-referrer-when-downgrade"}
          />
        </div>
      </section>
    </>
  );
};

export default Contact;

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | Stranica nije pronadjena</title>
        <meta
          name="description"
          content="Došlo je do problema pri pronalasku ove stranice, Centar za lični razvoj ima rešenje čak i za to, vratite se na početnu stranicu pa da nastavimo zajedno!"
        />
        <meta
          name="keywords"
          content="Psihologija, Lični razvoj, Anksioznost, Alternativno, Holističko, Holistički, Centar za lični razvoj, Depresija, Bolovi, Grupni rad, Podrška, Terapija, Edukacija, Refleksologija, Refleksoterapija, Masaža stopala, Zdravlje, Kurs, Obuka, Kurs refleksologije, Kurs masaže stopala, Bahove kapi, Balkanske esencije, Cvetne esencije"
        />

        <meta name="author" content="Sandra Kesejić" />
      </Helmet>
      <section className="section section-error bg-zinc-100 grid items-center">
        <div className="container max-w-screen-sm text-center">
          <h1 className="text-xl md:text-2xl lg:text-4xl mb-8 text-zinc-600 border-b-[1px] pb-8">
            404 error
          </h1>
          <p className="text-2xl text-zinc-700 mb-8">
            Stranica nije pronadjena!
          </p>
          <Link
            to={"/"}
            className="link flex max-w-[240px] justify-center items-center whitespace-nowrap gap-2 pl-4 pr-4 pt-3 pb-3 mx-auto no-underline rounded-sm bg-white shadow-sm text-zinc-700 hover:text-orange-500"
          >
            <span
              className="w-5 h-5 grid bg-cover -rotate-90 opacity-50"
              style={{
                backgroundImage: "url(../assets/images/icons/icon-arrow.png)",
              }}
            />
            <span>Vratite se na naslovnu!</span>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Error;

import React, { useState, useCallback, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useGlobalHook } from '../../context/AppProvider';

const links = [
  { id: 1, url: '/', title: 'Naslovna', },
  { id: 2, url: '/o-nama', title: 'O Nama', },
  { id: 3, url: '/holisticki-pristup', title: 'Holistički pristup', },
  { id: 4, url: '/edukacija-i-treninzi', title: 'Edukacija i treninzi', },
  { id: 5, url: '/individualne-konsultacije', title: 'Individualne konsultacije', },
  { id: 6, url: '/kontakt', title: 'Kontakt', },
];

const Nav = () => {
  const { freeze, unfreeze } = useGlobalHook();
  const [ showMenu, setShowMenu ] = useState(false);

  // toggle menu
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  // restore resize unfreeze
  const resizeHideMenu = useCallback(() => {
    if (window.innerWidth >= 992) {
      unfreeze();
      setShowMenu(false);
    }
  }, [ unfreeze ]);

  useLayoutEffect(() => {
    showMenu ? freeze() : unfreeze();
  }, [ showMenu, freeze, unfreeze ]);


  // escape event
  const escapeMenu = useCallback((event) => {
    if (event.key === "Escape") {
      unfreeze();
      setShowMenu(false);
    }
  }, [ unfreeze ]);  

  // trigger escape event
  useLayoutEffect(() => {
    document.addEventListener("keydown", escapeMenu, false);
    return () => {
      document.removeEventListener("keydown", escapeMenu, false);
    };
  }, [ escapeMenu ]);    


  // trigger escape event
  useLayoutEffect(() => {
    window.addEventListener("resize", resizeHideMenu, false);
    return () => {
      window.removeEventListener("resize", resizeHideMenu, false);
    };
  }, [ resizeHideMenu ]);   

  return (
    <>
      <nav className={`nav ${!showMenu ? '' : 'is-active'}`}>
        <div className='max-md:absolute max-md:inset-0 max-md:grid'>
          <div className='max-lg:absolute max-lg:inset-0 max-lg:overflow-y-auto'>
            <div className='container'>
              <ul>
                {links && links?.map(({ id, url, title }) => {
                  return (
                    <li key={id}>
                      <NavLink to={url} className='nav-link' onClick={() => setShowMenu(false)}>{title}</NavLink>
                    </li>                
                  )
                })}                                          
              </ul>
            </div>
          </div>
        </div>

        {/* <div className='scroll-indicator absolute bottom-0 w-full h-[3px] bg-white'>
          <div className={`progress h-full absolute top-0 left-0 bottom-0 bg-zinc-200`} style={{ width: `${scrolled}%`}} />
        </div>         */}
      </nav>
      <div className={`nav-overlay ${!showMenu ? '' : 'is-active'}`} onClick={() => setShowMenu(false)}/>
      <button type='button' className={`nav-trigger ${!showMenu ? '' : 'is-active'}`} onClick={() => toggleMenu()}>
        <span></span>
      </button>
    </>
  )
}

export default Nav;






// const nav = useRef(null);  
// const scrollAffix = () => {
//   let top = window.pageYOffset;
//   let content = document.querySelector('.main');

//   if (top > nav.current.offsetTop) {  
//     nav.current.classList.add('is-sticky');
//     content.classList.add('offset');
//   } else {
//     nav.current.classList.remove('is-sticky');
//     content.classList.remove('offset');
//   }
// }


// useEffect(() => {
//   window.addEventListener('scroll', scrollAffix, false);

//   return () => {
//     window.removeEventListener('scroll', scrollAffix, false);
//   }    
// }, []);
    
import React, { useEffect, useCallback } from 'react';

const Modal = React.forwardRef(({ children, show, toggle }, ref) => {
  const hiddenOverflow = (hidden) => {
    if (!hidden) {
      document.documentElement.className = 'overflow-auto';
    } else {
      document.documentElement.className = 'overflow-hidden';
    }
  }

  const escapeMenu = useCallback((event) => {
    if (event.key === "Escape") {
      toggle(false);
      document.documentElement.className = 'overflow-auto';
    }
  }, [ toggle ]);    

  useEffect(() => {
    document.addEventListener("keydown", escapeMenu, false);
    return () => {
      document.removeEventListener("keydown", escapeMenu, false);
    };
  }, [ escapeMenu ]);      

  useEffect(() => {
    show ? hiddenOverflow(true) : hiddenOverflow(false);
  }, [ show ]);

  return (
    <>
      <div className={`modal ${show ? 'is-active' : '' }`} ref={ref}>{children}</div>
      <div className={`modal-overlay  ${show ? 'is-active' : '' }`} onClick={() => toggle(false)} />
    </>
  )
});

export default Modal;
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// create context 
const AppContext = createContext(null);

// context provider
const AppProvider = ({ children }) => {
    const location = useLocation();
    const [ scrolled, setScrolled ] = useState(0);
    // scroll indicator 
    const scrollIndicator = () => {
		let currentPosition = document.body.scrollTop || document.documentElement.scrollTop;
		let pageScrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
		let scrollPercentage = currentPosition / pageScrollHeight * 100;
        setScrolled(scrollPercentage);
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollIndicator, false);
        return () => {
            window.removeEventListener('scroll', scrollIndicator, false);
        };
    }, [ ]);

    useEffect(() => {
        setScrolled(0);
    }, [ location ]);

    // scroll to top 
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    // scroll freeze
    const freeze = () => {
        let html = document.documentElement;
        let htmlPos = html.style.position;
        let scrollPos = html.scrollTop;

        if (!htmlPos) {
            html.style.position = 'fixed';
            html.style.width = '100%';
            html.style.height = '100%';
            html.style.top = '-' + scrollPos + 'px';
            html.style.overflowY = 'scroll';   
        }
    }

    // scroll unfreeze
    const unfreeze = () => {  
        let html = document.documentElement;
        let htmlPos = html.style.position;
        if (htmlPos === 'fixed') {
            html.style.position = 'static';
            html.scrollTop = -parseInt(html.style.top);
            html.style.position = '';
            html.style.top = '';
            html.style.width = '';
            html.style.height = '';
            html.style.overflowY = '';
        }
    }

    // context value
    const contextValue = {        
        // scroll to top 
        scrollToTop, 
        // scroll indicator 
        scrolled,
        // freeze scroll on click
        freeze,
        unfreeze,
    }

    return (
        <AppContext.Provider value={contextValue}>
          <div className="app">
            {children}
          </div>
        </AppContext.Provider>
    );
};
    
// call custom hook
const useGlobalHook = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider, useGlobalHook };










// toggle theme dark mode 
// toggleDarkMode,
// setToggleDarkMode,
// toggleThemes,
// toggle theme
// const [ toggleDarkMode, setToggleDarkMode ] = useState(() => {
//     const darkMode = window.localStorage.getItem('theme');
//     return JSON.parse(darkMode) ?? false;
// });

// const toggleThemes = () => setToggleDarkMode(!toggleDarkMode);  

// useLayoutEffect(() => {
//     window.localStorage.setItem('theme', JSON.stringify(toggleDarkMode));
//     if (JSON.parse(window.localStorage.theme) === true || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
//     document.documentElement.classList.add('dark');
//     } else {
//     document.documentElement.classList.remove('dark');
//     }
// }, [ toggleDarkMode ]);
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";
// Loading
import Loading from "../../components/loading/Loading";
// Modal
import Modal from "../../components/modal/Modal";
// init swiper core
SwiperCore.use([Autoplay, Navigation]);

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [showModalOne, setShowModalOne] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalThree, setShowModalThree] = useState(false);

  const modalOne = useRef(null);
  const modalTwo = useRef(null);
  const modalThree = useRef(null);

  const carouselOptions = {
    keyboard: true,
    grabCursor: false,
    centeredSlides: true,
    spaceBetween: 16,
    slidesPerView: 1,
    autoHeight: false,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      waitForTransition: true,
    },
    loop: true,
    navigation: {
      Navigation,
    },
    fadeEffect: {
      crossFade: true,
    },
    effect: "fade",
  };

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        setLoading(false);
      })();
    }, 700);
    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | Naslovna</title>
        <meta
          name="description"
          content="Centar za lični razvoj i savetovanje - Kesejić ima za cilj da edukuje i podrži svakog pojedinca u unaprešivanju i poboljšanju kvaliteta života, kako na fizičkom, tako i na mentalnom i emotivnom planu."
        />
        <meta
          name="keywords"
          content="Psihologija, Lični razvoj, Anksioznost, Alternativno, Holističko, Holistički, Centar za lični razvoj, Depresija, Bolovi, Grupni rad, Podrška, Terapija, Edukacija, Refleksologija, Refleksoterapija, Masaža stopala, Zdravlje, Kurs, Obuka, Kurs refleksologije, Kurs masaže stopala, Bahove kapi, Balkanske esencije, Cvetne esencije"
        />

        <meta name="author" content="Sandra Kesejić" />
      </Helmet>
      <section className="section section-home bg-teal-100">
        <div className="container max-w-screen-lg pb-16 text-center">
          <p className="paragraph font-normal mb-4 text-zinc-500">
            Dobrodošli u Centar za lični razvoj i savetovanja Kesejić
          </p>
          <h1 className="title block pb-4 md:inline text-center md:text-left">
            koji ima za cilj da edukuje i podrži svakog pojedinc koji želi da
            unapredi i poboljša kvalitet svog života, kako na fizičkom, tako i
            na mentalnom i emotivnom planu.
          </h1>
        </div>
        <div className="container">
          <Swiper
            {...carouselOptions}
            className="border-[3px] border-white bg-white"
          >
            <SwiperSlide>
              <figure className="figure figure-hero">
                <picture className="picture">
                  <source
                    media="(min-width: 1536px)"
                    srcSet="./assets/images/slider-1_2xl.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet="./assets/images/slider-1_xl.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 1024px)"
                    srcSet="./assets/images/slider-1_lg.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet="./assets/images/slider-1_md.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 640px)"
                    srcSet="./assets/images/slider-1_sm.jpg"
                    sizes="100vw"
                  />
                  <source
                    srcSet="./assets/images/slider-1_sm.jpg"
                    sizes="100vw"
                  />
                  <img
                    src="./assets/images/slider-1_2xl.jpg"
                    loading="lazy"
                    alt="Kurs refleksologije"
                  />
                </picture>
                <figcaption className="figcaption">
                  <div className="grid w-full h-full">
                    <h2 className="text-3xl text-center mb-6 text-zinc-500 font-sans">
                      Kurs refleksologije
                    </h2>
                    <p className="text-center mb-6 font-sans">
                      se održava svakog prvog vikenda u mesecu, u okviru kojeg
                      polaznici dobijaju literaturu, teoretsko i praktično
                      znanje i sertifikat po završetku.
                    </p>
                  </div>
                </figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="figure figure-hero">
                <picture className="picture">
                  <source
                    media="(min-width: 1536px)"
                    srcSet="./assets/images/slider-2_2xl.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet="./assets/images/slider-2_xl.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 1024px)"
                    srcSet="./assets/images/slider-2_lg.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet="./assets/images/slider-2_md.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 640px)"
                    srcSet="./assets/images/slider-2_sm.jpg"
                    sizes="100vw"
                  />
                  <source
                    srcSet="./assets/images/slider-2_sm.jpg"
                    sizes="100vw"
                  />
                  <img
                    src="./assets/images/slider-2_2xl.jpg"
                    loading="lazy"
                    alt="Meditacije po borovima"
                  />
                </picture>
                <figcaption className="figcaption">
                  <div className="grid w-full h-full">
                    <h2 className="text-3xl text-center mb-6 text-zinc-500 font-sans">
                      Meditacije po borovima
                    </h2>
                    <p className="text-center mb-4 font-sans">
                      <span>
                        svakog trećeg vikenda u mesecu organizujemo meditacije
                        pod borovima, više informacija i prijave putem telefona
                      </span>

                      <br />

                      <a
                        href="tel:+38162251227"
                        rel="noreferrer"
                        target="_self"
                        className="link"
                      >
                        +381 (0) 62 251 227
                      </a>
                    </p>
                  </div>
                </figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="figure figure-hero">
                <picture className="picture">
                  <source
                    media="(min-width: 1536px)"
                    srcSet="./assets/images/slider-3_2xl.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet="./assets/images/slider-3_xl.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 1024px)"
                    srcSet="./assets/images/slider-3_lg.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet="./assets/images/slider-3_md.jpg"
                    sizes="100vw"
                  />
                  <source
                    media="(min-width: 640px)"
                    srcSet="./assets/images/slider-3_sm.jpg"
                    sizes="100vw"
                  />
                  <source
                    srcSet="./assets/images/slider-3_sm.jpg"
                    sizes="100vw"
                  />
                  <img
                    src="./assets/images/slider-3_2xl.jpg"
                    loading="lazy"
                    alt="Uslužno korišćenje placa"
                  />
                </picture>
                <figcaption className="figcaption">
                  <div className="grid w-full h-full">
                    <h2 className="text-3xl text-center mb-6 text-zinc-500 font-sans">
                      Uslužno korišćenje placa
                    </h2>
                    <p className="text-center mb-4 font-sans">
                      <span>
                        Velik, uređen plac u najlepšem delu Kelebijske šume, pod
                        borovima, sa pomoćnim objektima i letnjikovcem.
                        <br />
                        <br />
                        Zbog položaja i okruženja u kojem se nalazi, idealno je
                        mesto za aktivnosti u prirodi kao što su časovi joge,
                        tai chi-a, meditacije, psihoterapije na otvorenom,
                        organizaciju različitih kurseva, team building
                        okupljanja.
                      </span>
                    </p>
                  </div>
                </figcaption>
              </figure>
            </SwiperSlide>
          </Swiper>

          <Modal show={showModalOne} toggle={setShowModalOne} ref={modalOne}>
            <div className="modal-inner relative w-full h-full pt-[calc(56.25%)]">
              {showModalOne ? (
                <>
                  {loading ? <Loading /> : null}
                  {!loading && (
                    <iframe
                      className="absolute inset-0 h-full w-full"
                      src={"https://www.youtube.com/embed/wIMSU8otS-g"}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </>
              ) : null}
            </div>
          </Modal>

          <Modal show={showModalTwo} toggle={setShowModalTwo} ref={modalTwo}>
            <div className="modal-inner relative w-full h-full pt-[calc(56.25%)]">
              {showModalTwo ? (
                <>
                  {loading ? <Loading /> : null}
                  {!loading && (
                    <iframe
                      className="absolute inset-0 h-full w-full"
                      src={"https://www.youtube.com/embed/5NV6Rdv1a3I"}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </>
              ) : null}
            </div>
          </Modal>

          <Modal
            show={showModalThree}
            toggle={setShowModalThree}
            ref={modalThree}
          >
            <div className="modal-inner relative w-full h-full pt-[calc(56.25%)]">
              {showModalThree ? (
                <>
                  {loading ? <Loading /> : null}
                  {!loading && (
                    <iframe
                      className="absolute inset-0 h-full w-full"
                      src={"https://www.youtube.com/embed/a5uQMwRMHcs"}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </>
              ) : null}
            </div>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Home;

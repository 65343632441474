import React from "react";
import { Helmet } from "react-helmet";

const Consultation = () => {
  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | Individualne Konsultacije</title>
        <meta
          name="description"
          content="Ako ste zainteresovani da unapredite i poboljšate kvalitet svog života, najbolji način za to je individualna konsultacija prilikom koje se na osnovu Vaše lične situacije biraju najbolje tehnike za konkretnu problematiku."
        />
        <meta
          name="keywords"
          content="Psihologija, Lični razvoj, Anksioznost, Alternativno, Holističko, Holistički, Centar za lični razvoj, Depresija, Bolovi, Grupni rad, Podrška, Terapija, Edukacija, Refleksologija, Refleksoterapija, Masaža stopala, Zdravlje, Kurs, Obuka, Kurs refleksologije, Kurs masaže stopala, Bahove kapi, Balkanske esencije, Cvetne esencije"
        />

        <meta name="author" content="Sandra Kesejić" />
      </Helmet>
      <section className="section section-consultation bg-purple-100">
        <div className="container max-w-screen-lg pb-16">
          <h1 className="title md:inline text-center md:text-left">
            Idividualne konsultacije
          </h1>
        </div>
        <div className="container max-w-screen-lg">
          <p className="paragraph">
            Individualne konsultacije nudimo u našem Centru u Subotici, online
            putem Skype ili pomoću drugih aplikacija.
          </p>
          <p className="paragraph mb-0">
            Molimo Vas da nas za Vaš termin za konsultaciju kontaktirate putem
            telefona{" "}
            <a
              href="tel:+38162251227"
              rel="noreferrer"
              target="_self"
              className="link"
            >
              +381 62/251-227
            </a>{" "}
            ili e-maila{" "}
            <a
              href="mailto:office@centarzalicnirazvoj.com"
              rel="noreferrer"
              target="_self"
              className="link"
            >
              office@centarzalicnirazvoj.com
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default Consultation;

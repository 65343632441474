import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
// header and footer components
import Header from '../components/header/Header';
import Nav from '../components/nav/Nav';
import Footer from '../components/footer/Footer';
// pages 
import Home from '../pages/naslovna/Home';
import About from '../pages/o-nama/About';
import Education from '../pages/edukacija-treninzi/Education';
import Access from '../pages/holisticki-pristup/Access';
import Consultation from '../pages/individualne-konsultacije/Consultation';
import Contact from '../pages/kontakt/Contact';
import Error from '../pages/error/Error';

const TRACKING_ID = "UA-118879687-1";

const Router = () => {
  const location  = useLocation();
  const [ initialized, setInitialized ] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(TRACKING_ID);
    }
    setInitialized(true);
  }, [ ]);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [ initialized, location ]);

  return (
    <>
      <Header />
      <Nav />
      <main className={`main`}>
        <Routes>
          {/* routes */}
          <Route index path='/' element={ <Home /> } />
          <Route path='/o-nama' element={ <About /> } />
          <Route path='/edukacija-i-treninzi' element={ <Education /> } />
          <Route path='/holisticki-pristup' element={ <Access /> } />
          <Route path='/individualne-konsultacije' element={ <Consultation /> } />
          <Route path='/kontakt' element={ <Contact /> } />
          {/* catch all */}
          <Route path={'*'} element={ <Error /> } />
        </Routes>
      </main>
      <Footer />
    </>
  )
}

export default Router;
import React from "react";
import { Helmet } from "react-helmet";

const Education = () => {
  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | Edukacije i treninzi</title>
        <meta
          name="description"
          content="Centar za lični razvoj Kesejić nudi različite vrste edukacija, radionica i treninga kako za lični, tako i ta poslovni razvoj i unapređenje kvaliteta života u svim oblastima."
        />
        <meta
          name="keywords"
          content="Psihologija, Lični razvoj, Anksioznost, Alternativno, Holističko, Holistički, Centar za lični razvoj, Depresija, Bolovi, Grupni rad, Podrška, Terapija, Edukacija, Refleksologija, Refleksoterapija, Masaža stopala, Zdravlje, Kurs, Obuka, Kurs refleksologije, Kurs masaže stopala, Bahove kapi, Balkanske esencije, Cvetne esencije"
        />

        <meta name="author" content="Sandra Kesejić" />
      </Helmet>
      <section className="section section-education bg-red-100">
        <div className="container max-w-screen-lg pb-16">
          <h1 className="title md:inline text-center md:text-left">
            Edukacije, kursevi, radionice i treninzi
          </h1>
        </div>
        <div className="container max-w-screen-lg">
          <p className="paragraph">
            U sklopu našeg Centra nudimo različite edukacije, kurseve, radionica
            i treninge, koji će biti redovno objavljivani na našoj početnoj
            stranici. Za više informacija molimo Vas da nam se obratite putem
            telefona{" "}
            <a
              href="tel:+38162251227"
              rel="noreferrer"
              target="_self"
              className="link"
            >
              +381 (0) 62 251 227{" "}
            </a>
            ili e-maila{" "}
            <a
              href="mailto:office@centarzalicnirazvoj.com"
              rel="noreferrer"
              target="_self"
              className="link"
            >
              office@centarzalicnirazvoj.com
            </a>
          </p>

          <p className="paragraph mb-4">
            <span className="font-semibold block">Kurs refleksologije</span>
          </p>

          <ul className="menu-item mb-8">
            <li className="item item-red-dot">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/dot-red.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              ODRŽAVA SE I ONLINE za sve koji žele, a nisu u mogućnosti da
              prisustvuju predavanju.
            </li>
            <li className="item item-hand-finger">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/hand-finger.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              <span className="text indent-7">
                Refleksologija je jedinstveni terapeutski sistem koji polazi od
                teorije da se na stopalama nalazi mapa delova tela i unutrasnjih
                organa, te da se masažom tih tačaka deluje na odgovarajuće
                organe ili delove tela.
              </span>
            </li>
            <li className="item">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/hand-finger.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              <span className="text">
                Refleksologija nije uobičajena masaža stopala kao deo masaže
                tela jer ovde je reč o specifičnoj tehnici pritiska koji se
                nanosi na različite, precizno određene tačke na stopalima koje
                su zapravo refleksne tačke odgovarajućih organa.
              </span>
            </li>
            <li className="item">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/hand-finger.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              Kurs se održava na placu u Kelebijskoj šumi
            </li>
          </ul>

          <p className="paragraph mb-8">Bagremova 96, Kelebija</p>

          <ul className="menu-item mb-8">
            <li className="item">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/check-green.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              Na obuci dobijate literaturu ( knjigu) gde se nalazi svo teoretsko
              znanje. U knjizi imate pregršt slika koje olakšavaju učenje i
              primenu.
            </li>
            <li className="item">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/check-green.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              Naknadno svakog prvog vikenda u mesecu se održavaju vežbanja i
              konsultacije kome je potrebno više (ne naplaćuje se dodatno)
              <span className="icon ml-2">
                <img
                  className="img"
                  src="./assets/images/icons/flavor-rose.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
            </li>
            <li className="item">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/check-green.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              <span className="text indent-7">
                U cenu je uključeno vežbanje svakog prvog vikenda u mesecu kao i
                druženje sa refleksolozima u šumi tokom leta. Gde delimo znanja,
                iskustva i vežbamo
              </span>
              <span className="icon ml-2">
                <img
                  className="img"
                  src="./assets/images/icons/flavor-rose.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
            </li>
            <li className="item">
              <span className="icon">
                <img
                  className="img"
                  src="./assets/images/icons/check-green.png"
                  alt="Centar za Licni Razvoj"
                />
              </span>
              U cenu je uključeno vežbanje svakog prvog vikenda u mesecu kao i
              druženje sa refleksolozima u šumi tokom leta. Gde delimo znanja,
              iskustva i vežbamo
              <span className="icon">Icon</span>
            </li>
          </ul>

          <p className="paragraph mb-4">
            Prijave na
            <span className="icon">
              <img
                className="img"
                src="./assets/images/icons/hand-finger-down.png"
                alt="Centar za Licni Razvoj"
              />
            </span>
          </p>

          <p className="paragraph mb-8 inline-flex">
            <span className="icon">
              <img
                className="img"
                src="./assets/images/icons/phone-red.png"
                alt="Centar za Licni Razvoj"
              />
            </span>
            <span>
              <a
                href="tel:+38162251227"
                rel="noreferrer"
                target="_self"
                className="link text-zinc-700 no-underline"
              >
                +381 (0) 62 251 227
              </a>
            </span>
          </p>

          <p className="paragraph mb-4">
            <span className="font-semibold block">Meditacije pod borovima</span>
          </p>

          <p>
            svakog trećeg vikenda u mesecu, prijave su obavezne jer je broj
            mesta ograničen
          </p>
          {/* <p className="paragraph">
            <span className="font-semibold block">Vođene meditacije</span>
            <span>
              petkom u 20h, prijave su obavezne jer su mesta ograničena
            </span>
          </p>
          <p className="paragraph">
            <span className="font-semibold block">Radionica</span>
            <span>Transformativna radionica samospoznaje i samopomoći</span>
          </p>
          <p className="paragraph mb-0">
            <span className="font-semibold block">U četiri oka</span>
            <span>
              Od nas za Vas: besplatno live veče jednom u mesecu gde u četiri
              oka razgovaramo o različitim akutnim temama. Pridružite nam se sa
              aktuelnim pitanjima i pratite objave na{" "}
              <a
                href="https://www.instagram.com/centarzalicnirazvojkesejic/"
                title="Instagram"
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                Instagram-u
              </a>{" "}
              i{" "}
              <a
                href=" https://www.facebook.com/refleksoterapija.rs?mibextid=LQQJ4dc"
                title="Facebook"
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                Facebook-u
              </a>
              .
            </span>
          </p> */}
        </div>
      </section>
    </>
  );
};

export default Education;

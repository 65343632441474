import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Centar za Licni Razvoj | O Nama</title>
        <meta name="description" content="U našem Centru nudimo različite vrste tehnika, dukacija i individualnih konsultacija zasnovanih na holističkom pristupu. Ovakav način rada uzima u obzir sve spoljašnje i unutrašnje uticaje koju su doveli do izvesnog stanja da bi na najbolji mogući način uz odgovarajuće tehnike došli do optimalog rešenja." />
      </Helmet>    
      <section className='section section-about bg-green-100'>
        <div className='container max-w-screen-lg pb-16'>
          <h1 className='title md:inline text-center md:text-left'>O nama...</h1>
        </div>

        <div className='container max-w-screen-lg'>
          <p className='paragraph'>Želite da unapredite i poboljšate svoj život, zdravlje, mentalno i psihofizičko stanje? Da upoznate sebe bolje i spoznate obrasce ponašanja i blokade koje Vas sprečavaju u tome da ostvarite svoj maksimum u svim oblastima života? Onda ste kod nas na pravom mestu.</p>
          <p className="paragraph">Centar za lični razvoj i savetovanje <strong className='font-medium'>Kesejić</strong> posvećen je edukaciji iz oblasti samospoznaje i ličnog razvoja. Naš centar ima za cilj da edukuje, ali pre svega podrži svakog pojedinca koji želi da unapredi i poboljša kvalitet svog života.</p>
          <p className="paragraph">Pored individualnih konsultacija nudimo i različite vrste seminara, kurseva, radionica i predavanja, kao i redovne aktivnosti kao što su na primer naše večeri vođenih meditacija, energetskih vežbi i slično. </p>
          
          <ul className='list bg-white pl-4 pr-4 rounded-sm mb-8'>
            <p className="paragraph font-semibold mb-8 text-zinc-600 text-center">Neki od naših glavnih fokusa u radu obuhvataju sledeće oblasti:</p>
            
            <li className='list-item'>- Individualne treninge i konsultacije iz oblasti samospoznaje i ličnog razvoja</li>
            <li className='list-item'>- Podršku u procesu ličnih i poslovnih promena</li>
            <li className='list-item'>- Grupne radionice sa temama ličnog i poslovnog usavršavanja</li>
            <li className='list-item'>- Grupne radionice za edukaciju, podršku i razmenu iskustava</li>
            <li className='list-item'>- Energetske vežbe za ostvarivanje svog maksimalnog potencijala</li>
            <li className='list-item'>- Rad na bračnim i partnerskim odnosima</li>
            <li className='list-item'>- Rad na fizičkim i mentalnim blokadama, itd.</li>
          </ul>

          <p className="paragraph">Naši terapeuti dolaze iz različitih oblasti, što našu ponudu čini raznosvrsnom i omogućava holistički pristup i nešablonski, individualan rad.</p>
          <p className="paragraph">Svi kursevi i radionice se odvijaju ili uživo u manjim grupama, ili online. Sve individualne konsultacije se odvijaju uživo u našem Centru. Za više informacija kontaktirajte nas <Link to={'/kontakt'} className='link'>ovde</Link>. </p>
        
          {/* <p className="paragraph font-semibold text-zinc-600 text-center">Naši terapeuti</p> */}

          <div className='container max-w-screen-lg pb-16'>
            <h2 className='title text-center'>Naši terapeuti</h2>
          </div>


          <div className='grid place-items-center gap-4'>
            <figure className='figure figure-avatar hover-figure-avatar'>
              <div className='figure-image'>
                <img className='img object-cover rounded-full' title='Sandra Kesejić' alt='Sandra Kesejić' src='./assets/images/sandra-kesejic.jpg' />
              </div>
              <figcaption className='figure-caption'>
                <small className='text-white text-lg'>Sandra Kesejić</small>
              </figcaption>
            </figure>
            <div className='w-full bg-white rounded-sm border-2 border-white pt-8 pb-8 pl-4 pr-4 md:p-12 shadow-sm'>
              <p className='paragraph text-base !leading-7 mb-4'><strong className='font-medium'>Sandra Kesejić</strong> je osnivač i predsednik Centra za lični razvoj i savetovanje – <strong className='font-medium'> Kesejić</strong>, trener ličnog i poslovnog razvoja, procesor Dubokog P.E.A.T.-a, kouč konstruktivističkog usmerenja, autor programa Samospoznajom do samorealizacije, kao i Antistres programa.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Pronašavši svoju inspiraciju i probudivši svoje potencijale, <strong className='font-medium'>Sandra Kesejić</strong> stručno, ljudski i profesionalno pomaže i drugima da učine isto.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Kao edukator u oblasti samospoznaje, ličnog i poslovnog razvoja, a visoko obrazovana u tom pravcu, <strong className='font-medium'>Sandra Kesejić</strong> poseduje alate, metode, načine i viziju, koje želi i ume da prenese drugima.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Uverena da je svako u stanju da spozna svoje unutrašnje snage i da izgradi svoje ciljeve, mogućnosti, kao i svoju sveukupnost, <strong className='font-medium'>Sandra </strong> u tom pravcu pomaže ljudima.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Njen rad zasnovan je kako na sopstvenom iskustvu tako i na kontinuiranom usavršavanju po pitaju ličnog rasta i razvoja kroz različite vidove obuka i angažovanja kroz koje je prolazila i u okviru kojih i dalje radi na sebi. <strong className='font-medium'>Sandra Kesejić</strong> je više godina unazad deo psihodramskih radionica. Kouč je konstruktivističkog usmerenja kao i Reiki majstor i energoterapeut.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Konstantno se usavršava u oblastima energetske psihologije kao i konvencionalne psihologije, ekonomije, interpersonalnog razvoja i komunikacionih veština u kojima je stekla i stiče znanja, praksu i odgovarajuće diplome i sertifikate.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Prepoznavanje prilika, lični razvoj i otkrivanje sopstvenih potencijala važan su, a neretko i presudni podstrek u životu i radu.</p>
              <p className='paragraph text-base !leading-7 mb-4'>Misija <strong className='font-medium'>Sandre Kesejić</strong> je da svakog pojedinca podstakne i podrži na putu ka samoostvarivanju i uspehu. Svako ima ogromne mogućnosti i sve što je potrebno da postignemo najviše ciljeve jeste da ono što posedujemo iskoristimo na pravi način. </p>
              <p className='paragraph text-base !leading-7 mb-4'>Upravo to je ono na čemu <strong className='font-medium'>Sandra </strong> radi i ono što se može probuditi i produbiti u radu sa njom.</p>
              <p className='paragraph text-base !leading-7 mb-4'><strong className='font-medium'>Sandra Kesejić</strong> uspešno organizuje i vodi individualne i grupne treninge i konsultacije, a kao stručnjak za odnose sa javnošću, ima 20-ogodišnje iskustvo u vođenju prodajnog tima, trgovini i međuljudskim odnosima.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About;